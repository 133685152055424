export default [
  {
    text: "Afeganistão",
    value: "AFG",
  },
  {
    text: "África do Sul",
    value: "ZAF",
  },
  {
    text: "Albânia",
    value: "ALB",
  },
  {
    text: "Alemanha",
    value: "DEU",
  },
  {
    text: "Andorra",
    value: "AND",
  },
  {
    text: "Angola",
    value: "AGO",
  },
  {
    text: "Anguilla",
    value: "AIA",
  },
  {
    text: "Antártida",
    value: "ATA",
  },
  {
    text: "Antígua e Barbuda",
    value: "ATG",
  },
  {
    text: "Antilhas Holandesas",
    value: "ANT",
  },
  {
    text: "Arábia Saudita",
    value: "SAU",
  },
  {
    text: "Argélia",
    value: "DZA",
  },
  {
    text: "Argentina",
    value: "ARG",
  },
  {
    text: "Armênia",
    value: "ARM",
  },
  {
    text: "Aruba",
    value: "ABW",
  },
  {
    text: "Austrália",
    value: "AUS",
  },
  {
    text: "Áustria",
    value: "AUT",
  },
  {
    text: "Azerbaijão",
    value: "AZE",
  },
  {
    text: "Bahamas",
    value: "BHS",
  },
  {
    text: "Bahrein",
    value: "BHR",
  },
  {
    text: "Bangladesh",
    value: "BGD",
  },
  {
    text: "Barbados",
    value: "BRB",
  },
  {
    text: "Belarus",
    value: "BLR",
  },
  {
    text: "Bélgica",
    value: "BEL",
  },
  {
    text: "Belize",
    value: "BLZ",
  },
  {
    text: "Benin",
    value: "BEN",
  },
  {
    text: "Bermudas",
    value: "BMU",
  },
  {
    text: "Bolívia",
    value: "BOL",
  },
  {
    text: "Bósnia-Herzegovínia",
    value: "BIH",
  },
  {
    text: "Botsuana",
    value: "BWA",
  },
  {
    text: "Brasil",
    value: "BRA",
  },
  {
    text: "Brunei Darussalam",
    value: "BRN",
  },
  {
    text: "Bulgária",
    value: "BGR",
  },
  {
    text: "Burkina Faso",
    value: "BFA",
  },
  {
    text: "Burundi",
    value: "BDI",
  },
  {
    text: "Butão",
    value: "BTN",
  },
  {
    text: "Cabo Verde",
    value: "CPV",
  },
  {
    text: "Camarões",
    value: "CMR",
  },
  {
    text: "Cambodja",
    value: "KHM",
  },
  {
    text: "Canadá",
    value: "CAN",
  },
  {
    text: "Cazaquistão",
    value: "KAZ",
  },
  {
    text: "Chade",
    value: "TCD",
  },
  {
    text: "Chile",
    value: "CHL",
  },
  {
    text: "China",
    value: "CHN",
  },
  {
    text: "Chipre",
    value: "CYP",
  },
  {
    text: "Cingapura",
    value: "SGP",
  },
  {
    text: "Colômbia",
    value: "COL",
  },
  {
    text: "Congo (Rep.)",
    value: "COG",
  },
  {
    text: "Coréia do Norte",
    value: "PRK",
  },
  {
    text: "Coréia do Sul",
    value: "KOR",
  },
  {
    text: "Costa do Marfim",
    value: "CIV",
  },
  {
    text: "Costa Rica",
    value: "CRI",
  },
  {
    text: "Croácia",
    value: "HRV",
  },
  {
    text: "Cuba",
    value: "CUB",
  },
  {
    text: "Dinamarca",
    value: "DNK",
  },
  {
    text: "Djibuti",
    value: "DJI",
  },
  {
    text: "Dominica",
    value: "DMA",
  },
  {
    text: "Egito",
    value: "EGY",
  },
  {
    text: "El Salvador",
    value: "SLV",
  },
  {
    text: "Emirados Árabes Unidos",
    value: "ARE",
  },
  {
    text: "Equador",
    value: "ECU",
  },
  {
    text: "Eritréia",
    value: "ERI",
  },
  {
    text: "Eslováquia",
    value: "SVK",
  },
  {
    text: "Eslovênia",
    value: "SVN",
  },
  {
    text: "Espanha",
    value: "ESP",
  },
  {
    text: "Estados Unidos da América",
    value: "USA",
  },
  {
    text: "Estônia",
    value: "EST",
  },
  {
    text: "Etiópia",
    value: "ETH",
  },
  {
    text: "Fiji",
    value: "FJI",
  },
  {
    text: "Filipinas",
    value: "PHL",
  },
  {
    text: "Finlândia",
    value: "FIN",
  },
  {
    text: "França",
    value: "FRA",
  },
  {
    text: "Gabão",
    value: "GAB",
  },
  {
    text: "Gâmbia",
    value: "GMB",
  },
  {
    text: "Gana",
    value: "GHA",
  },
  {
    text: "Geórgia",
    value: "GEO",
  },
  {
    text: "Gibraltar",
    value: "GIB",
  },
  {
    text: "Grã-Bretanha",
    value: "GBR",
  },
  {
    text: "Granada",
    value: "GRD",
  },
  {
    text: "Grécia",
    value: "GRC",
  },
  {
    text: "Groenlândia",
    value: "GRL",
  },
  {
    text: "Guadalupe",
    value: "GLP",
  },
  {
    text: "Guam",
    value: "GUM",
  },
  {
    text: "Guatemala",
    value: "GTM",
  },
  {
    text: "Guernsey",
    value: "GGY",
  },
  {
    text: "Guiana",
    value: "GUY",
  },
  {
    text: "Guiana Francesa",
    value: "GUF",
  },
  {
    text: "Guiné",
    value: "GIN",
  },
  {
    text: "Guiné Equatorial",
    value: "GNQ",
  },
  {
    text: "Guiné-Bissau",
    value: "GNB",
  },
  {
    text: "Haiti",
    value: "HTI",
  },
  {
    text: "Países Baixos",
    value: "NLD",
  },
  {
    text: "Honduras",
    value: "HND",
  },
  {
    text: "Hong Kong",
    value: "HKG",
  },
  {
    text: "Hungria",
    value: "HUN",
  },
  {
    text: "Iêmem",
    value: "YEM",
  },
  {
    text: "Ilha Bouvet(TerritóriodaNoruega)",
    value: "BVT",
  },
  {
    text: "Man",
    value: "IMN",
  },
  {
    text: "Christmas",
    value: "CXR",
  },
  {
    text: "Pitcairn",
    value: "PCN",
  },
  {
    text: "Reunião",
    value: "REU",
  },
  {
    text: "Ilhas Aland",
    value: "ALA",
  },
  {
    text: "Cayman",
    value: "CYM",
  },
  {
    text: "Cocos",
    value: "CCK",
  },
  {
    text: "Comores",
    value: "COM",
  },
  {
    text: "Cook",
    value: "COK",
  },
  {
    text: "Faroe",
    value: "FRO",
  },
  {
    text: "Falkland",
    value: "FLK",
  },
  {
    text: "Geórgia do Sul e Sandwich do Sul",
    value: "SGS",
  },
  {
    text: "Ilha Heard e Ilhas McDonald(TerritóriodaAustrália)",
    value: "HMD",
  },
  {
    text: "Marianas",
    value: "MNP",
  },
  {
    text: "Marshall",
    value: "MHL",
  },
  {
    text: "Ilhas Menores Distantes dos Estados Unidos",
    value: "UMI",
  },
  {
    text: "Norfolk",
    value: "NFK",
  },
  {
    text: "Seycheles",
    value: "SYC",
  },
  {
    text: "Salomão",
    value: "SLB",
  },
  {
    text: "Ilhas Svalbard e Jan Mayen",
    value: "SJM",
  },
  {
    text: "Toquelau",
    value: "TKL",
  },
  {
    text: "Turcks e Caicos",
    value: "TCA",
  },
  {
    text: "Virgens Americanas",
    value: "VIR",
  },
  {
    text: "Ilhas Virgens(Inglaterra)",
    value: "VGB",
  },
  {
    text: "Wallis e Futuna",
    value: "WLF",
  },
  {
    text: "Índia",
    value: "IND",
  },
  {
    text: "Indonésia",
    value: "IDN",
  },
  {
    text: "Irã",
    value: "IRN",
  },
  {
    text: "Iraque",
    value: "IRQ",
  },
  {
    text: "Irlanda",
    value: "IRL",
  },
  {
    text: "Islândia",
    value: "ISL",
  },
  {
    text: "Israel",
    value: "ISR",
  },
  {
    text: "Itália",
    value: "ITA",
  },
  {
    text: "Jamaica",
    value: "JAM",
  },
  {
    text: "Japão",
    value: "JPN",
  },
  {
    text: "Jersey",
    value: "JEY",
  },
  {
    text: "Jordânia",
    value: "JOR",
  },
  {
    text: "Quênia",
    value: "KEN",
  },
  {
    text: "Kiribati",
    value: "KIR",
  },
  {
    text: "Kuwait",
    value: "KWT",
  },
  {
    text: "Laos",
    value: "LAO",
  },
  {
    text: "Letônia",
    value: "LVA",
  },
  {
    text: "Lesoto",
    value: "LSO",
  },
  {
    text: "Líbano",
    value: "LBN",
  },
  {
    text: "Libéria",
    value: "LBR",
  },
  {
    text: "Líbia",
    value: "LBY",
  },
  {
    text: "Liechtenstein",
    value: "LIE",
  },
  {
    text: "Lituânia",
    value: "LTU",
  },
  {
    text: "Luxemburgo",
    value: "LUX",
  },
  {
    text: "Macau",
    value: "MAC",
  },
  {
    text: "Macedônia",
    value: "MKD",
  },
  {
    text: "Madagascar",
    value: "MDG",
  },
  {
    text: "Malásia",
    value: "MYS",
  },
  {
    text: "Malavi",
    value: "MWI",
  },
  {
    text: "Maldivas",
    value: "MDV",
  },
  {
    text: "Mali",
    value: "MLI",
  },
  {
    text: "Malta",
    value: "MLT",
  },
  {
    text: "Marrocos",
    value: "MAR",
  },
  {
    text: "Martinica",
    value: "MTQ",
  },
  {
    text: "Maurício",
    value: "MUS",
  },
  {
    text: "Mauritânia",
    value: "MRT",
  },
  {
    text: "Mayotte",
    value: "MYT",
  },
  {
    text: "México",
    value: "MEX",
  },
  {
    text: "Micronésia",
    value: "FSM",
  },
  {
    text: "Moçambique",
    value: "MOZ",
  },
  {
    text: "Moldávia",
    value: "MDA",
  },
  {
    text: "Mônaco",
    value: "MCO",
  },
  {
    text: "Mongólia",
    value: "MNG",
  },
  {
    text: "Montenegro",
    value: "MNE",
  },
  {
    text: "Montserrat",
    value: "MSR",
  },
  {
    text: "Mianmar",
    value: "MMR",
  },
  {
    text: "Namíbia",
    value: "NAM",
  },
  {
    text: "Nauru",
    value: "NRU",
  },
  {
    text: "Nepal",
    value: "NPL",
  },
  {
    text: "Nicarágua",
    value: "NIC",
  },
  {
    text: "Níger",
    value: "NER",
  },
  {
    text: "Nigéria",
    value: "NGA",
  },
  {
    text: "Niue",
    value: "NIU",
  },
  {
    text: "Noruega",
    value: "NOR",
  },
  {
    text: "Nova Caledônia",
    value: "NCL",
  },
  {
    text: "Nova Zelândia",
    value: "NZL",
  },
  {
    text: "Omã",
    value: "OMN",
  },
  {
    text: "Palau",
    value: "PLW",
  },
  {
    text: "Panamá",
    value: "PAN",
  },
  {
    text: "Papua-Nova Guiné",
    value: "PNG",
  },
  {
    text: "Paquistão",
    value: "PAK",
  },
  {
    text: "Paraguai",
    value: "PRY",
  },
  {
    text: "Peru",
    value: "PER",
  },
  {
    text: "Polinésia Francesa",
    value: "PYF",
  },
  {
    text: "Polônia",
    value: "POL",
  },
  {
    text: "Porto Rico",
    value: "PRI",
  },
  {
    text: "Portugal",
    value: "PRT",
  },
  {
    text: "Catar",
    value: "QAT",
  },
  {
    text: "Quirguistão",
    value: "KGZ",
  },
  {
    text: "Centro-Africana",
    value: "CAF",
  },
  {
    text: "Congo (Rep. Dem.)",
    value: "COD",
  },
  {
    text: "Dominicana",
    value: "DOM",
  },
  {
    text: "Tcheca (Rep.)",
    value: "CZE",
  },
  {
    text: "Romênia",
    value: "ROM",
  },
  {
    text: "Ruanda",
    value: "RWA",
  },
  {
    text: "Rússia",
    value: "RUS",
  },
  {
    text: "Saara Ocidental",
    value: "ESH",
  },
  {
    text: "São Vicente e Granadinas",
    value: "VCT",
  },
  {
    text: "Samoa Americana",
    value: "ASM",
  },
  {
    text: "Samoa",
    value: "WSM",
  },
  {
    text: "San Marino",
    value: "SMR",
  },
  {
    text: "Santa Helena",
    value: "SHN",
  },
  {
    text: "Santa Lúcia",
    value: "LCA",
  },
  {
    text: "SãoBartolomeu",
    value: "BLM",
  },
  {
    text: "São Cristóvão e Névis",
    value: "KNA",
  },
  {
    text: "SãoMartim",
    value: "MAF",
  },
  {
    text: "São Tomé e Príncipe",
    value: "STP",
  },
  {
    text: "Senegal",
    value: "SEN",
  },
  {
    text: "Serra Leoa",
    value: "SLE",
  },
  {
    text: "Sérvia",
    value: "SRB",
  },
  {
    text: "Síria",
    value: "SYR",
  },
  {
    text: "Somália",
    value: "SOM",
  },
  {
    text: "Sri-Lanka",
    value: "LKA",
  },
  {
    text: "Saint Pierre e Miquelon",
    value: "SPM",
  },
  {
    text: "Suazilândia",
    value: "SWZ",
  },
  {
    text: "Sudão",
    value: "SDN",
  },
  {
    text: "Suécia",
    value: "SWE",
  },
  {
    text: "Suíça",
    value: "CHE",
  },
  {
    text: "Suriname",
    value: "SUR",
  },
  {
    text: "Tadjiquistão",
    value: "TJK",
  },
  {
    text: "Tailândia",
    value: "THA",
  },
  {
    text: "Taiwan",
    value: "TWN",
  },
  {
    text: "Tanzânia",
    value: "TZA",
  },
  {
    text: "Território Britânico do Oceano Índico",
    value: "IOT",
  },
  {
    text: "Terras Austrais e Antárt. da França",
    value: "ATF",
  },
  {
    text: "Palestina",
    value: "PSE",
  },
  {
    text: "TimorLeste",
    value: "TMP",
  },
  {
    text: "Togo",
    value: "TGO",
  },
  {
    text: "Tonga",
    value: "TON",
  },
  {
    text: "Trinidade e Tobago",
    value: "TTO",
  },
  {
    text: "Tunísia",
    value: "TUN",
  },
  {
    text: "Turcomenistão",
    value: "TKM",
  },
  {
    text: "Turquia",
    value: "TUR",
  },
  {
    text: "Tuvalu",
    value: "TUV",
  },
  {
    text: "Ucrânia",
    value: "UKR",
  },
  {
    text: "Uganda",
    value: "UGA",
  },
  {
    text: "Uruguai",
    value: "URY",
  },
  {
    text: "Uzbequistão",
    value: "UZB",
  },
  {
    text: "Vanuatu",
    value: "VUT",
  },
  {
    text: "Vaticano",
    value: "VAT",
  },
  {
    text: "Venezuela",
    value: "VEN",
  },
  {
    text: "Vietnã",
    value: "VNM",
  },
  {
    text: "Zâmbia",
    value: "ZMB",
  },
  {
    text: "Zimbabue",
    value: "ZWE",
  },
  {
    text: "Açores e Madeira",
    value: "XA",
  },
  {
    text: "Ascenção",
    value: "XB",
  },
  {
    text: "Canárias e Tenerife",
    value: "XC",
  },
  {
    text: "Ilhas Menores da França",
    value: "XD",
  },
  {
    text: "Tristão da Cunha",
    value: "XE",
  },
  {
    text: "Wake",
    value: "XF",
  },
  {
    text: "Midway",
    value: "XG",
  },
];

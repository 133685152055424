<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppAlert from "@/features/bootstrap/Alert";
import AppInputGroup from "@/features/general/InputGroup";
import AppIcon from "@/features/general/Icons";
import debounce from "lodash/debounce";
import removeArrayIndex from "lodash/remove";
import { mask } from "vue-the-mask";
import { EmbedMap } from "tray-maps";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, minLength } from "@vuelidate/validators";
import { isValidCep } from "./validators/address";
import countries from "./countries";

export default {
  name: "AppAddress",
  components: {
    AppIcon,
    AppInputGroup,
    AppAlert,
  },

  directives: {
    mask,
  },

  setup() {
    /**
     * Objeto que mantém o estado das validações do vuelidate
     */
    return { v$: useVuelidate({ $autoDirty: true }) };
  },

  data() {
    return {
      alert_dismissCountDown: 10,
      BILLING_ADDRESS_TYPE: "0",
      ACTIVE_ADDRESS: "1",
      isSaving: false,
      zipCodePending: false,
      differentRecipient: false,
      loading: true,
      countries,
      isCountryFocused: true,
    };
  },

  /**
   * Existem comportamentos diferentes
   * para cada pagina (edição/novo-endereço)
   */
  mounted() {
    if (this.isEditPage) {
      this.fetch();
      this.setRecipientWithCurrentCustomer();
      return;
    }

    if (this.isNewAddressPage) {
      this.setRecipientWithCurrentCustomer();
    }

    // Ativa o endereço nas lojas sem multi endereço
    this.setAddress({ active: this.isMultiAddress ? "" : "1" });
    this.loading = false;
  },

  created() {
    this.toggleCountryFocus();
  },

  beforeMount() {
    this.clearMessages();
    this.clearAddress();

    sessionStorage.removeItem("zipCode");
  },

  methods: {
    isValidCep,
    ...mapActions("Address", [
      "clearAddress",
      "clearMessages",
      "setAddress",
      "setBackendErrors",
      "setMessage",
    ]),

    /**
     * Define o destinatário com o nome do cliente
     * @return {undefined}
     */
    setRecipientWithCurrentCustomer() {
      const mustRecipientBeTheCustomerName = this.storeSettings?.show_address_recipient_field === '1'
          && !this.differentRecipient;

      if (mustRecipientBeTheCustomerName) {
        this.setAddress({recipient: this.customerName});
      }
    },

    /**
     * Valida o campo para não permitir caracteres especiais
     * @param {Event} event
     * @return {undefined}
     */
    validateInput(event) {
      const regex = /[-’/`'"~!#*$@_%+=.,&(){}[\]|;:”<>?\\]/gi
                    
      if (regex.test(event.key)) {
        event.preventDefault();
        return false;
      }

      return true;
    },

    /**
     * Checa se o usuario está interagindo com o input de país
     */
    toggleCountryFocus() {
      const isElementFocused =
        this.$refs.countrySelect === document.activeElement;
      this.isCountryFocused = isElementFocused;
    },

    /**
     * Busca o endereço na API e atualiza o endereço definido
     */
    fetch() {
      const addressPath = `addresses/${this.$route.params.addressId}`;
      this.loading = true;
      this.$http
        .get(addressPath)
        .then((response) => {
          const { data } = response;
          this.setAddress({ ...data });
          this.prepareForShow();
          this.loading = false;
          this.setEmbedMap();
        })
        .catch((error) => {
          this.$router.push({ name: "addresses.list" });
          return error;
        });
    },

    /**
     * Antes da remoção do loading dispara eventos
     * e manipula propridades do endereço
     * para uma melhor exibição dos dados
     */
    prepareForShow() {
      this.removeZipCodeFromDescription();
      this.differentRecipient = Boolean(!this.recipient);
    },

    /**
     * Limpa o campo de descrição caso o valor
     * seja um cep válido
     */
    removeZipCodeFromDescription() {
      if (!this.isValidCep(this.description)) {
        return;
      }

      this.description = "";
      this.v$.description.$touch();
    },

    onSubmit() {
      const formData = {
        customer_id: this.customerId,
        active: this.active ? "1" : "0",
        city: this.city,
        complement: this.complement,
        country: this.country,
        id: this.$route.params.addressId,
        description: this.description,
        neighborhood: this.neighborhood,
        number: this.number,
        recipient: this.recipient,
        state: this.state,
        address: this.street,
        zip_code: this.zipCode,
        address_type: this.type === "delivery" ? "1" : "0",
      };

      this.isSaving = true;
      this.clearMessages();

      const request =
        this.isEditPage && this.isMultiAddress
          ? this.$http.put
          : this.$http.post;

      const route =
        this.isEditPage && this.isMultiAddress
          ? `addresses/${this.$route.params.addressId}`
          : "addresses";

      return request(route, formData)
        .then((response) => {
          const { id } = response.data.message;
          this.$router.push({
            name: "addresses.edit",
            params: {
              addressId: id,
            },
          });

          this.setMessage({
            variant: "success",
            text: `${this.langs.addresses["success-message"]}`,
          });

          this.scrollTop();
          this.isSaving = false;

          this.$router.push({ name: "addresses.list" });
        })
        .catch((error) => {
          this.scrollTop();
          this.isSaving = false;

          const { data } = error;

          this.setMessage({
            variant: "danger",
            text: `${this.langs.addresses["danger-message"]}`,
          });

          this.setBackendErrors({ ...data.errors });
        });
    },

    /**
     * Posiciona o scroll da pagina no topo
     * @return void
     */
    scrollTop() {
      window.scroll({
        top: 130,
        left: 0,
        behavior: "smooth",
      });
    },

    /**
     * Busca as informações de endereço a partir de um cep e
     * atualiza os campos baseado nos dados retornados
     * @return {bool|Promisse}
     */
    // eslint-disable-next-line
    getAddressByZipCode: debounce(function () {
      const isSameZipCode = sessionStorage.getItem("zipCode") === this.zipCode;
      if (isSameZipCode || this.v$.zipCode.$invalid) {
        return false;
      }

      this.zipCodePending = true;

      const route = `address-zipcode/${this.zipCode}`;
      return this.$http
        .get(route)
        .then((response) => {
          const { address, city, neighborhood, state } = response.data.message;

          this.city = city || "";
          this.neighborhood = neighborhood || "";
          this.state = state || "";
          this.street = address || "";
          this.number = "";
          this.complement = "";

          this.zipCodePending = false;

          sessionStorage.setItem("zipCode", this.zipCode);
        })

        .catch(() => {
          this.zipCodePending = false;
        });
    }, 500),

    /**
     * Verifica se o campo é opcional
     * @param {string} field
     * @return {bool}
     */
    isOptional(field) {
      if (!field) {
        return false;
      }

      const optionalFields = ["complement"];
      return optionalFields.indexOf(field) !== -1;
    },

    /**
     * Retorna o state de acordo com as opções do validador
     * https://monterail.github.io/vuelidate/
     *
     * @param {object} $dirty Se o input já sofreu interação do usuário
     * @param {object} $error Se o input é inválido e já sofreu interação do usuário, ou
     * seja, quando [$invalid] e [$dirty] são true.
     * @param {object} $invalid Se o input não "passa" em alguma validação
     * @param {object} $model Valor do input
     * @param {string} validationMessage Mensagem de validação customizada
     * @returns {boolean|string}
     */
    getValidatorState(
      { $dirty, $error, $invalid, $model } = {},
      validationMessage,
      fieldName = null
    ) {
      const emptyOptional = this.isOptional(fieldName) && $model.length === 0;

      if (emptyOptional) {
        return "initial";
      }

      if (validationMessage && validationMessage.length) {
        return "invalid";
      }

      const wasTouched = $dirty;
      const hasNoErrors = !$error;

      if (wasTouched) {
        return hasNoErrors;
      }

      const isAnyValidationInvalid = $invalid;
      const isEmpty = !$model;

      const isNeutral = hasNoErrors && (isAnyValidationInvalid || isEmpty);
      return isNeutral ? "initial" : !isAnyValidationInvalid;
    },

    /**
     * Converte o number, de quando for 0 para 's/n'
     */
    zeroToSn() {
      this.number = this.number === "0" ? "s/n" : this.number;
    },

    /**
     * Recupera a aplicação que exibirá o mapa
     * @return {undefined}
     */
    setEmbedMap() {
      const embedMap = new EmbedMap({
        containerId: "map",
        marker: this.fullCustomerAddress,
        zoom: 16,
        size: "340x440",
        token: this.address.token_map,
      });

      embedMap.draw();
    },
  },

  computed: {
    ...mapGetters(["langs", "storeSettings", "storeInfo"]),
    ...mapState("Customer", {
      customerId: (state) => state.customer.list.id,
      customerName: (state) => state.customer.list.name,
    }),
    ...mapState("Address", {
      address: (state) => state.address.address,
      messages: (state) => state.address.messages,
      backendErrors: (state) => state.address.backendErrors,
    }),

    /**
     * Verifica se a loja permite o cadastro de
     * endereços internacionais
     * international_shiping
     *  - 0 Somente endereço nacional
     *  - 1 Somente endereço internacional
     *  - 2 Endereço nacional e internacional
     * @return {boolean}
     */
    allowInternationalAddress() {
      if (this.country && this.country !== "BRA") {
        return true;
      }

      const { features } = this.storeInfo;
      const { international_shipping: internationalAddressConfig } =
        this.storeSettings;

      return (
        internationalAddressConfig !== "0" &&
        features.indexOf("international-address") !== -1
      );
    },

    /**
     * Define se o input group de país vai usar o state padrão ou retornar initial caso o input esteja focado
     * @return {String}
     */
    regularStateOrInitial() {
      if (this.isCountryFocused) {
        return "initial";
      }

      return this.getValidatorState(
        this.v$.country,
        this.backendErrors.country
      );
    },

    /**
     * Verifica se o endereço é internacional
     * @return {boolean}
     */
    isInternationalAddress() {
      return this.country && this.country !== "BRA";
    },

    /**
     * Verifica a label a ser usada para o input de país
     * @return {string}
     */
    hasCountryDefined() {
      if (this.country) {
        return this.langs.addresses.country;
      }

      return "";
    },

    /**
     * Verifica a label a ser usada para o CEP caso o endereço seja internacional
     * @returns {string}
     */
    useInternationalPostalCode() {
      if (this.isInternationalAddress) {
        return this.langs.addresses["postal-code"];
      }

      return "CEP";
    },

    /**
     * Verifica a label a ser usada para o estado caso o endereço seja internacional
     * @returns {string}
     */
    useInternationalState() {
      if (this.isInternationalAddress) {
        return this.langs.addresses.state_province;
      }

      return this.langs.addresses.state;
    },

    /**
     * Verifica a label a ser usada ao salvar
     * @returns {string}
     */
    labelForSaving() {
      if (this.isSaving) {
        return this.langs.profile.saving;
      }

      return this.langs.profile.save_changes;
    },

    /**
     * Verifica se a pagina é a de edição
     * @return string
     */
    isEditPage() {
      return this.$route.name === "addresses.edit";
    },

    /**
     * Verifica se a pagina é a de novo endereço
     * @returns {boolean}
     */
    isNewAddressPage() {
      return this.$route.name === "addresses.new";
    },

    active: {
      get() {
        return this.address.active;
      },
      set(active) {
        this.setBackendErrors({ active: "" });
        this.setAddress({ active });
      },
    },

    city: {
      get() {
        return this.address.city;
      },
      set(city) {
        this.setBackendErrors({ city: "" });
        this.setAddress({ city });
      },
    },

    complement: {
      get() {
        return this.address.complement;
      },
      set(complement) {
        this.setBackendErrors({ complement: "" });
        this.setAddress({ complement });
      },
    },

    country: {
      get() {
        return this.address.country;
      },
      set(country) {
        this.city = "";
        this.number = "";
        this.neighborhood = "";
        this.state = "";
        this.street = "";
        this.zipCode = "";
        this.recipient = "";
        this.complement = "";
        this.differentRecipient = false;
        sessionStorage.removeItem("zipCode");
        this.setBackendErrors({ country: "" });
        this.setAddress({ country });
      },
    },

    description: {
      get() {
        return this.address.description;
      },
      set(description) {
        this.setBackendErrors({ description: "" });
        this.setAddress({ description });
      },
    },

    neighborhood: {
      get() {
        return this.address.neighborhood;
      },
      set(neighborhood) {
        this.setBackendErrors({ neighborhood: "" });
        this.setAddress({ neighborhood });
      },
    },

    number: {
      get() {
        return this.address.number;
      },
      set(number) {
        this.setBackendErrors({ number: "" });
        this.setAddress({ number });
      },
    },

    recipient: {
      get() {
        return this.address.recipient;
      },
      set(recipient) {
        this.setBackendErrors({ recipient: "" });
        this.setAddress({ recipient });
      },
    },

    state: {
      get() {
        return this.address.state;
      },
      set(state) {
        this.setBackendErrors({ state: "" });
        this.setAddress({ state });
      },
    },

    street: {
      get() {
        return this.address.street;
      },
      set(street) {
        this.setBackendErrors({ street: "" });
        this.setAddress({ street });
      },
    },

    type: {
      get() {
        // eslint-disable-next-line
        return this.address.type == "0" ? "billing" : "delivery";
      },
      set(type) {
        this.setBackendErrors({ type: "" });
        this.setAddress({ type });
      },
    },

    zipCode: {
      get() {
        return this.address.zip_code;
      },
      set(zipCode) {
        this.setBackendErrors({ zip_code: "" });
        this.setAddress({ zip_code: zipCode });
      },
    },

    tokenMap: {
      get() {
        return this.address.token_map;
      },
      set(tokenMap) {
        this.setBackendErrors({ token_map: "" });
        this.setAddress({ token_map: tokenMap });
      },
    },

    /**
     * Verifica se o campo destinatário é obrigatório
     * @returns {boolean}
     */
    isRecipientRequired() {
      return this.storeSettings.address_recipient_required === "1";
    },

    /**
     * Verifica se o campo destinatário será exibido
     * @return {boolean}
     */
    showRecipientField() {
      return this.storeSettings.show_address_recipient_field === "1";
    },

    /**
     * Verifica se o multi endereço está ativo
     * @return {boolean}
     */
    isMultiAddress() {
      // eslint-disable-next-line
      return this.storeSettings.multi_address == "1";
    },

    /**
     * Retorna o titulo da pagina baseado na rota do usúario
     * @return {string} título da página
     */
    pageTitle() {
      const addressType =
        this.type === "delivery"
          ? this.langs.addresses.delivery
          : this.langs.addresses.billing;

      return `${this.langs.addresses["edit-address-for"]} ${addressType}`;
    },

    /**
     * Retorna os países de acordo com as configurações
     * @return {array}
     */
    countriesOptions() {
      const { international_shipping: internationalAddressConfig } =
        this.storeSettings;

      if (internationalAddressConfig === "1") {
        removeArrayIndex(this.countries, (country) => country.value === "BRA");
      }
      return this.countries;
    },

    /**
     * Retorna a máscara do código postal.
     *
     * @return {string}
     * */
    cepMask() {
      if (this.isInternationalAddress) {
        return "XXXXXXXXXXXXXXXXXXXX";
      }
      return "#####-###";
    },

    /**
     * Retorna o padrão de validação de caracteres do código postal.
     *
     * @return {object}
     * */
    cepMaskTokens() {
      return {
        "#": { pattern: /\d/ },
        C: { pattern: /.*/ },
      };
    },

    /**
     * Retorna o endereço completo do cliente
     * @return {string}
     */
    fullCustomerAddress() {
      return `${this.address.street},
              ${this.address.city},
              ${this.address.number},
              ${this.address.state}`;
    },
  },

  validations() {
    const validations = {
      active: {},
      complement: {},

      city: {
        required,
      },

      country: {
        required,
      },

      description: {
        required,
      },

      neighborhood: {
        required,
      },

      number: {
        required,
      },

      recipient: {
        required: requiredIf(
          () => this.differentRecipient && this.showRecipientField && this.isRecipientRequired
        ),
        validLastName: (fullName) => {
          if (!this.isRecipientRequired) {
            return true;
          }

          const splitedName = fullName.split(" ");
          const lastName = splitedName[1];

          return lastName !== undefined
              && lastName.length > 1;
        },
      },

      state: {
        required,
      },

      street: {
        required,
      },

      type: {
        required,
      },

      zipCode: {
        required,
        minLength: minLength(9),
      },
    };

    if (this.isInternationalAddress) {
      delete validations.zipCode.minLength;
    }

    return validations;
  },

  watch: {
    /**
     * Observa as mudancas no estado das configs da loja. Caso as configuracoes
     * estejam setadas para mostrar o campo de destinatário e a opcao de usar um destinatario
     * diferente do cadastro esteja ativada, o campo de destinatario sera prenchido com o nome do usuário.
     *
     * @param settings
     * @return {undefined}
     */
    storeSettings(settings) {
      if (!settings) {
        return;
      }

      if (this.isNewAddressPage) {
        this.setRecipientWithCurrentCustomer();
      }
    },

    /**
     * Limpa campo "recebedor" quando "destinatário diferente" for marcado
     * @param {bool} isChecked
     * @return {void}
     */
    differentRecipient(isChecked) {
      if (isChecked) {
        this.recipient = "";
        this.v$.recipient.$touch();
        return;
      }
      this.setAddress({recipient: this.customerName});
    },
  },
};
</script>

<template>
  <section class="app__address-list">
    <header class="app__address-list__header">
      <h3
        class="app__address-list__title--3"
        :class="{ app__loading: loading }"
        v-text="pageTitle"
      ></h3>
      <app-alert :messages="messages" />
    </header>
    <form
      class="app__address-edit-form"
      action="PUT"
      @submit.prevent="onSubmit"
    >
      <div class="app__address-edit">
        <div class="row app__address-edit__fields">
          <div class="col-md-6">
            <div
              v-if="this.type === 'delivery'"
              class="app__data-edit__input__wrapper app__address-edit__fields--main-address"
            >
              <input
                type="radio"
                id="address-set-main-address-checkbox"
                class="app__data-edit__input__checkbox__input"
                value="1"
                @change="v$.active.$touch()"
                v-model="active"
                data-cy="primary-address-select"
              />
              <label
                for="address-set-main-address-checkbox"
                class="app__data-edit__input__checkbox"
                :class="{
                  'app__data-edit__input__checkbox--is-checked': active,
                  app__loading: loading,
                }"
              >
                <app-icon v-show="active" prefix="fa" name="check"> </app-icon>
              </label>
              <label
                for="address-set-main-address-checkbox"
                class="app__data-edit__input__checkbox__label"
                :class="{ app__loading: loading }"
              >
                {{ this.langs.addresses["define-main-address"] }}
              </label>
            </div>

            <app-input-group
              :label="`${this.langs.addresses['get-address-name']}`"
              identifier="address-description"
              :icon="{ prefix: 'fa', name: 'street-view' }"
              :loading="loading"
              :value="description"
              :state="
                this.getValidatorState(
                  v$.description,
                  backendErrors.description
                )
              "
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['get-address-name']}`"
                  maxlength="100"
                  id="address-description"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !description,
                    app__loading: loading,
                  }"
                  v-model.trim="description"
                  @change="v$.description.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.description.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.description.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ this.langs.addresses["address-name"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.description"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.description[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>
            <app-input-group
              v-if="this.allowInternationalAddress"
              :label="this.hasCountryDefined"
              identifier="address-country"
              :icon="{ prefix: 'fa', name: 'globe-americas' }"
              :loading="loading"
              :value="country"
              :state="regularStateOrInitial"
            >
              <template v-slot:input>
                <select
                  ref="countrySelect"
                  :placeholder="`${this.langs.addresses['country']}`"
                  id="address-country"
                  class="app__input-group__select form-select"
                  :style="!isCountryFocused && { background: 'none' }"
                  :class="{
                    'app__input-group__select--has-float-label': country,
                    'app__input-group__select--is-empty': !country,
                    app__loading: loading,
                  }"
                  @focus="toggleCountryFocus"
                  @focusout="toggleCountryFocus"
                  v-model="country"
                  @change="v$.country.$touch()"
                >
                  <option
                    v-for="(option, index) in [
                      {
                        text: `${this.langs.addresses['country']}`,
                        value: '',
                        disabled: true,
                      },
                      ...countriesOptions,
                    ]"
                    :key="index"
                    :selected="index === 0 ? 'selected' : false"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </template>
            </app-input-group>

            <div class="row">
              <div
                :class="{
                  'col-12': this.isInternationalAddress,
                  'col-8 col-md-7': !this.isInternationalAddress,
                }"
              >
                <app-input-group
                  :label="this.useInternationalPostalCode"
                  identifier="address-zipcode"
                  :icon="{ prefix: 'fa', name: 'home' }"
                  :loading="loading"
                  :value="zipCode"
                  :state="
                    zipCodePending
                      ? 'pending'
                      : this.getValidatorState(
                          v$.zipCode,
                          backendErrors.zip_code
                        )
                  "
                >
                  <template v-slot:input>
                    <input
                      type="text"
                      :placeholder="
                        this.useInternationalPostalCode
                          ? this.langs.addresses['postal-code']
                          : 'CEP'
                      "
                      id="address-cep"
                      class="app__input-group__input form-control"
                      :class="{
                        'app__input-group__input--has-float-label': true,
                        'app__input-group__input--is-empty': !zipCode,
                        app__loading: loading,
                      }"
                      v-mask="cepMask"
                      v-model.trim="zipCode"
                      @change="v$.zipCode.$touch()"
                      @input="
                        isInternationalAddress ? null : getAddressByZipCode()
                      "
                    />
                  </template>
                  <template v-slot:feedbacks>
                    <small
                      v-if="v$.zipCode.$error"
                      class="app__input-group__feedbacks"
                    >
                      <span
                        v-if="!v$.zipCode.required.$response"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ this.langs.addresses["postal-code"] }}
                      </span>
                      <span
                        v-if="v$.zipCode.required && !v$.zipCode.minLength"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ this.langs.addresses["invalid-postal-code"] }}
                      </span>
                    </small>
                  </template>
                  <template v-slot:feedbacks--backend>
                    <small
                      class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                    >
                      <span
                        v-if="backendErrors.zip_code"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ backendErrors.zip_code[0] }}
                      </span>
                    </small>
                  </template>
                </app-input-group>
              </div>

              <div
                v-if="!this.isInternationalAddress"
                class="col-4 col-md-5 app__vertical-align app__address-edit__fields--zipcode-link"
              >
                <a
                  href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                  target="_blank"
                  class="app__link"
                  :class="{ app__loading: loading }"
                >
                  {{ this.langs.addresses["unknown-postal-code"] }}
                </a>
              </div>
            </div>

            <app-input-group
              :label="`${this.langs.addresses['street-name']}`"
              identifier="address-street"
              :icon="{ prefix: 'fa', name: 'map-marker-alt' }"
              :loading="loading"
              :value="street"
              :state="this.getValidatorState(v$.street, backendErrors.street)"
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['street-name']}`"
                  maxlength="200"
                  id="address-street"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !street,
                    app__loading: loading,
                  }"
                  v-model.trim="street"
                  @change="v$.street.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.street.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.street.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ this.langs.addresses["address"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.street"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.street[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <app-input-group
              :label="`${this.langs.addresses['address-number']}`"
              identifier="address-number"
              :icon="{ prefix: 'fa', name: 'sign' }"
              :loading="loading"
              :value="number"
              :state="this.getValidatorState(v$.number, backendErrors.number)"
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['address-number']}`"
                  maxlength="5"
                  id="address-number"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !number,
                    app__loading: loading,
                  }"
                  v-model.trim="number"
                  @change="v$.number.$touch()"
                  @blur="zeroToSn()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.number.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.number.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ this.langs.addresses["address-number"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.number"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.number[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <app-input-group
              :label="`${this.langs.addresses['complement']}`"
              identifier="address-complement"
              :icon="{ prefix: 'far', name: 'building' }"
              :loading="loading"
              :value="complement"
              :state="
                this.getValidatorState(
                  v$.complement,
                  backendErrors.complement,
                  'complement'
                )
              "
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['complement']}`"
                  maxlength="60"
                  id="address-complement"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !complement,
                    app__loading: loading,
                  }"
                  v-model.trim="complement"
                  @change="v$.complement.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.complement.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.complement.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ this.langs.addresses["get-complement"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.complement"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.complement[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <app-input-group
              :label="`${this.langs.addresses['neighbourhood']}`"
              identifier="address-neighborhood"
              :icon="{ prefix: 'far', name: 'map' }"
              :loading="loading"
              :value="neighborhood"
              :state="
                this.getValidatorState(
                  v$.neighborhood,
                  backendErrors.neighborhood
                )
              "
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['neighbourhood']}`"
                  maxlength="100"
                  id="address-neighborhood"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !neighborhood,
                    app__loading: loading,
                  }"
                  v-model.trim="neighborhood"
                  @change="v$.neighborhood.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.neighborhood.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.neighborhood.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ this.langs.addresses["get-neighbourhood"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.neighborhood"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.neighborhood[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <div class="row">
              <div
                :class="{
                  'col-md-7': !this.isInternationalAddress,
                  'col-12': this.isInternationalAddress,
                }"
              >
                <app-input-group
                  :label="`${this.langs.addresses['city']}`"
                  identifier="address-city"
                  :icon="{ prefix: 'fa', name: 'city' }"
                  :loading="loading"
                  :value="city"
                  :state="this.getValidatorState(v$.city, backendErrors.city)"
                >
                  <template v-slot:input>
                    <input
                      type="text"
                      :placeholder="`${this.langs.addresses['city']}`"
                      id="address-city"
                      maxlength="100"
                      class="app__input-group__input form-control"
                      :disabled="this.isInternationalAddress ? false : true"
                      :class="{
                        'is-disabled': !isInternationalAddress,
                        'app__input-group__input--has-float-label': true,
                        'app__input-group__input--is-empty': !city,
                        app__loading: loading,
                      }"
                      v-model.trim="city"
                      @change="v$.city.$touch()"
                    />
                  </template>
                  <template v-slot:feedbacks>
                    <small
                      v-if="v$.city.$error"
                      class="app__input-group__feedbacks"
                    >
                      <span
                        v-if="!v$.city.required.$response"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ this.langs.addresses["get-city"] }}
                      </span>
                    </small>
                  </template>
                  <template v-slot:feedbacks--backend>
                    <small
                      class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                    >
                      <span
                        v-if="backendErrors.city"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ backendErrors.city[0] }}
                      </span>
                    </small>
                  </template>
                </app-input-group>
              </div>
              <div
                :class="{
                  'col-md-5': !this.isInternationalAddress,
                  'col-12': this.isInternationalAddress,
                }"
              >
                <app-input-group
                  :label="this.useInternationalState"
                  identifier="address-state"
                  :icon="{ prefix: 'fa', name: 'globe-americas' }"
                  :loading="loading"
                  :value="state"
                  :state="this.getValidatorState(v$.state, backendErrors.state)"
                >
                  <template v-slot:input>
                    <input
                      type="text"
                      :placeholder="this.useInternationalState"
                      id="address-state"
                      class="app__input-group__input form-control"
                      :maxLength="this.isInternationalAddress ? '' : '2'"
                      :disabled="this.isInternationalAddress ? false : true"
                      :class="{
                        'is-disabled': !this.isInternationalAddress,
                        'app__input-group__input--has-float-label': true,
                        'app__input-group__input--is-empty': !state,
                        app__loading: loading,
                      }"
                      v-model.trim="state"
                      @change="v$.state.$touch()"
                    />
                  </template>
                  <template v-slot:feedbacks>
                    <small
                      v-if="v$.state.$error"
                      class="app__input-group__feedbacks"
                    >
                      <span
                        v-if="!v$.state.required.$response"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ this.langs.addresses["get-state"] }}
                      </span>
                    </small>
                  </template>
                  <template v-slot:feedbacks--backend>
                    <small
                      class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                    >
                      <span
                        v-if="backendErrors.state"
                        class="app__input-group__feedbacks__feedback"
                      >
                        {{ backendErrors.state[0] }}
                      </span>
                    </small>
                  </template>
                </app-input-group>
              </div>
            </div>

            <div
              class="app__data-edit__input__wrapper"
              v-if="showRecipientField"
              data-cy="recipient-address-select"
            >
              <input
                type="checkbox"
                id="address-different-recipient-checkbox"
                class="app__data-edit__input__checkbox__input"
                v-model.trim="differentRecipient"
              />
              <label
                for="address-different-recipient-checkbox"
                class="app__data-edit__input__checkbox"
                :class="{
                  'app__data-edit__input__checkbox--is-checked':
                    differentRecipient,
                  app__loading: loading,
                }"
              >
                <app-icon v-show="differentRecipient" prefix="fa" name="check">
                </app-icon>
              </label>
              <label
                for="address-different-recipient-checkbox"
                class="app__data-edit__input__checkbox__label"
                :class="{ app__loading: loading }"
              >
                {{ this.langs.addresses["different-recipient"] }}
              </label>
            </div>

            <app-input-group
              :label="`${this.langs.addresses['recipient']}`"
              identifier="address-recipient"
              :icon="{ prefix: 'far', name: 'user' }"
              :loading="loading"
              :value="recipient"
              :state="
                this.getValidatorState(v$.recipient, backendErrors.recipient)
              "
            >
              <template v-slot:input>
                <input
                  type="text"
                  :placeholder="`${this.langs.addresses['recipient']}`"
                  id="address-recipient"
                  class="app__input-group__input form-control"
                  :disabled="!differentRecipient"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !recipient,
                    'is-disabled': !differentRecipient,
                    app__loading: loading,
                  }"
                  v-model.trim="recipient"
                  @change="v$.recipient.$touch()"
                  @keypress="validateInput"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  v-if="v$.recipient.$error"
                  class="app__input-group__feedbacks"
                >
                  <span
                    v-if="!v$.recipient.required.$response"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ langs.addresses["recipient"] }}
                  </span>
                  <span v-if="v$.recipient.required.$response
                      && !v$.recipient.validLastName.$response && !backendErrors.recipient[0]"
                      class="app__input-group__feedbacks__feedback"
                  >
                    {{ langs.addresses["recipient-invalid"] }}
                  </span>
                </small>
              </template>
              <template v-slot:feedbacks--backend>
                <small
                  class="app__input-group__feedbacks app__input-group__feedbacks--backend"
                >
                  <span
                    v-if="backendErrors.recipient"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.recipient[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>
          </div>

          <div class="col-lg-6">
            <div div class="app__address-edit__map">
              <div v-show="!loading" id="map" />
              <span class="app__address-edit__map-info">
                {{ this.langs.addresses["map-info"] }}
              </span>
            </div>
          </div>
        </div>

        <div class="text-center app__address-edit__actions">
          <button
            class="app__button app__button--primary app__button--no-margin"
            :class="{
              app__loading: loading,
              'app__button--disabled': isSaving || !v$.$anyDirty,
            }"
            type="submit"
            :disabled="isSaving || !v$.$anyDirty"
          >
            {{ this.labelForSaving }}
            <app-icon
              class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
              prefix="fa"
              name="sync-alt"
              v-show="isSaving"
            >
            </app-icon>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
